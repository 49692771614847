import { request } from '@/utils/'


// 获取管制扇区列表
export const get_list = params => request.get(`/api/control_sector/`, { params })


// 添加管制扇区
export const add_info = data => request.post(`/api/control_sector/`, data)


// 修改管制扇区
export const edit_info = data => request.patch(`/api/control_sector/${data.id}/`, data)

